define(['app','$window','siteObj'], (app,$window,siteObj) => {
  const youCamMakeUp = () => {

    const component = {};

    component.config = {
      selectors: {
        vtoButton: '.youCamMakeUp_vto_button',
        shadeFinderButton: '.youCamMakeUp_shadeFinder_button',
        openShadeFinderOnLoad: '[data-YMKOnLoad="true"]',
      },
      paths: {
        checkout: '/my.basket',
        basket: '/basketinterface.json?'
      }
    };

    component.init = (element) => {
      component.element = element;
      component.vtoButton = component.element.querySelector(component.config.selectors.vtoButton);
      component.shadeFinderButton = component.element.querySelector(component.config.selectors.shadeFinderButton);
      component.openShadeFinderOnLoad = component.element.querySelector(component.config.selectors.openShadeFinderOnLoad);
      $window.ymkAsyncInit = () => {
        $window.YMK.init({
          autoOpen: false,
          skinSmooth: 1,
          brand: 'boots'
        });
      };
      component.bind();
      component.openOnLoad();

    };

    component.getLastAddedItemData = (simpleBasket) => {
      let lastAddedItem = simpleBasket.lastAddedItem;
      let data = {
        worthValue: lastAddedItem.worthValue,
        productId: lastAddedItem.productId,
        productTitle: lastAddedItem.productTitle,
        productUrl: lastAddedItem.productURL,
        productQuantity: simpleBasket.lastAddedQuantity,
        productQuantityRequested: simpleBasket.lastAddedQuantityRequested,
        productPrice: lastAddedItem.price,
        basketTotalPrice: simpleBasket.totalprice,
        recommendations: simpleBasket.recommendations,
        basketTotalItems: simpleBasket.totalUnits,
        message: simpleBasket.message,
        lastAddedMaxPerOrder: lastAddedItem.maxPerOrder,
        loyaltySchemePoints: simpleBasket.loyaltySchemePoints
      };
      let imageURL = '';
      if (lastAddedItem.productImages && lastAddedItem.productImages.largeproduct) {
        imageURL = lastAddedItem.productImages.largeproduct;
      }
      data.productImageUrl = imageURL;
      return data;
    };

    component.addToBasketSuccess = (data) => {
      app.publish('globalBasketItemsCount/updateGlobalBasketItemsCount');
      let jsonData = JSON.parse(data);
      let modalData = component.getLastAddedItemData(jsonData.simpleBasket);
      app.publish('productQuickbuy/hideModal', '', false);
      app.publish('BasketItemAdded', jsonData);
      if (jsonData.simpleBasket.lastAddedItem) {
        let lastAddedItemSku = jsonData.simpleBasket.lastAddedItem.productId;
        if ($window.dataLayer.length) {
          $window.dataLayer[0].lastAddedItemSku = lastAddedItemSku.toString();;
        };
      }
      return Promise.resolve();
    };

    component.addToBasketError = (err) => {
      app.publish('productQuickbuy/stopLoading', '', false);
      app.publish('productQuickbuy/showError', '', false);
      app.publish('addedToBasketModal/showError', '', false);
    };

    component.getQueryObject = (productData) => {
      return {
        productId: productData.ecommerce.add.products[0].id,
        siteId: siteObj.siteID,
        siteDefaultLocale: siteObj.siteDefaultLocale,
        siteSubsiteCode: siteObj.subsiteCode,
        variationLength: 1,
        quantity: 1,
        fromWishlist: false
      }
    };

    component.querify = (obj) => Object.entries(obj).map(([key, value]) => `${key}=${value}`).join('&');

     component.addToBasket = async (productData) =>  {
      const queryObj = component.getQueryObject(productData);
      if (queryObj && Object.keys(queryObj).length > 0) {
        app.ajax.get({
          url: component.config.paths.basket + component.querify(queryObj),
          success: component.addToBasketSuccess,
          error: component.addToBasketError
        });
      }
    }

    component.bind = () => {
      component.vtoButton && component.vtoButton.addEventListener('click', () => { $window.YMK.open() })
      component.shadeFinderButton && component.shadeFinderButton.addEventListener('click', () => { $window.YMK.openShadeFinder() });

      if ($window.YMK.addEventListener) {
        $window.YMK.addEventListener('foundationTool', function(value) {

          if(value['action'] === 'Started Tool') {
            component.hideShadeFinderButton();
          }

          component.dataLayerUpdate('foundationTool',value);

          if(value['event'] === 'addToCart' && value['category'].includes('Purchase')) {
            component.addToBasket(value);
          }

          if(value['event'] === 'checkout') {
            $window.location.href = component.config.paths.checkout;
          }

        });
      };
    };

    component.dataLayerUpdate = (event, value) => {
        $window.dataLayer.push({
          event: event,
          eventData: {
            eventCategory: value['category'],
            eventAction: value['action'],
            eventLabel: value['label'],
            eventPage: ''
          }
        });
    };

    component.hideShadeFinderButton = () => {
      if (!component.shadeFinderButton) {
        return
      };
        component.shadeFinderButton.style.display = "none"
    }

    component.openOnLoad = () => {
      if (component.openShadeFinderOnLoad) {
        $window.YMK.openShadeFinder && $window.YMK.openShadeFinder();
      }
    };

    return component;
  };
  return youCamMakeUp;
});
